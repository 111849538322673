import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Chrome from "../images/chrome.svg";
import ReactPlayer from 'react-player';
import Poster from "../images/poster.png";

//import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`tree style tab manager`, `chrome extension`, `forest`, `forest tree style tab manager`]}
        title="Tree Style Tab Manager"
      />
      <section className="hero">
        <div className=" relative  pt-32 sm:px-0 pt-32 sm:pt-32">
          <div className="text-center px-3 lg:px-0">
            <h1
              //className="my-4 text-2xl md:text-3xl lg:text-5xl font-black leading-tight capitalize"
              className="text-center text-3xl sm:text-5xl md:text-6xl font-bold leading-tight max-w-3xl mx-auto pt-4"
            >
              Say goodbye to messy tabs, with one tool.
            </h1>
            <p
              //className="leading-normal text-gray-800 text-base md:text-xl lg:text-2xl mb-10"
              className="text-center text-lg sm:text-2xl pt-8 text-dark-100 max-w-xl mx-auto mb-10"
            >
              Start browsing the internet the way you deserve and know where all your tabs are with ease.
           </p>

            <a
                className="group bg-white shadow-md px-3  xs:px-6 py-2 rounded-lg inline-flex items-center space-x-4 mr-4 hover:bg-gray-900"
                href="https://chrome.google.com/webstore/detail/treely-tree-style-tab-man/hbledhepdppepjnbnohiepcpcnphimdj"
            >
              <div className="logo">
                <img
                  className="w-6 h-6"
                  src={Chrome} />
              </div>
              <div className="text">
                <p className=" text-xs text-gray-600 group-hover:text-white" style={{ fontSize: "0.5rem" }}>Download on the</p>
                <p className=" text-xs font-semibold text-black group-hover:text-white">Chrome Store</p>
              </div>
            </a>

            <a
               className="group bg-white shadow-md px-3 xs:px-6 py-2 rounded-lg inline-flex items-center space-x-4 hover:bg-gray-900"
               href="https://microsoftedge.microsoft.com/addons/detail/treely-tree-style-tab-ma/lkohffhefinkknccfhhjpbnhgiibnkda?hl=en-GB"
            >
              <div className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-black w-6 h-6 group-hover:text-white" viewBox="0 0 24 24"><path d="M.93 10.653c.705-5.551 4.49-10.586 11.277-10.653 4.098.078 7.469 1.935 9.473 5.475 1.008 1.848 1.322 3.788 1.389 5.928v2.515h-15.061c.069 6.212 9.139 6 13.046 3.262v5.056c-1.522.915-4.331 1.764-7.225 1.764-7.198 0-10.13-5.411-10.112-9.05-.111-4.469 2.221-7.425 5.844-9.106-.768.954-1.352 2.004-1.658 3.818h8.505s.497-5.082-4.813-5.082c-5.01.177-8.622 3.09-10.665 6.073z" /></svg>

              </div>
              <div className="text">
                <p className=" text-xs text-gray-600 group-hover:text-white" style={{ fontSize: "0.5rem" }}>Download for</p>
                <p className=" text-xs font-semibold text-black group-hover:text-white">Microsoft Edge</p>
              </div>
            </a>


          </div>

          <div className="flex justify-center container mx-auto px-2 xs:px-16 sm:px-8 md:px-8 lg:px-32 mt-20 mb-20 ">
            {
              /*
              <video preload="auto" src={"https://www.youtube.com/embed/W81dlS5G8Gs"} className={"hidden-sm"} autoPlay muted loop />
              */
            }
            <div className="video-container">
              <ReactPlayer
                className={"video"}
                url='https://www.youtube.com/watch?v=LmoyfGFlPGE'
                width='100%'
                height='100%'
                controls={true}
                light={Poster}
              />
            </div>


          </div>


        </div>
      </section>

      <section className="pb-12">

        <div className="container mx-auto px-4 lg:pt-24 lg:pb-24">

          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-center text-3xl sm:text-5xl font-bold leading-tight max-w-3xl mx-auto">Features</h2>
              <p className="text-center text-lg sm:text-xl pt-4 text-gray-500 max-w-xl mx-auto">
                Having iterated hundreds of times using feedbacks from users, Forest is here to transform the way you interact with your browser.
              </p>
            </div>
          </div>

          <div className="flex flex-wrap justify-start mt-16 lg:px-32">
            <div className="w-full lg:w-4/12 px-4 mb-10 card py-4">
              <div
                className="text-black"
              >
                <svg className={"w-6 h-6"} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M13 24h-2l-.001-9.818-5.216-3.804.008-.011-.005-.004-.589.809 4.811 3.498-.009 4.139c-4.561-.881-7.999-4.717-7.999-9.309 0-5.243 4.481-9.5 10-9.5s10 4.257 10 9.5c0 4.592-3.436 8.427-7.997 9.309l-.01-4.139 4.81-3.498-.588-.809-.001.001.01.014-5.225 3.81.001 9.812zm-.001-14.01l.004 2.958.997-.727-.007-1.748 3.015-2.193-.589-.808-.005.003.001.001-3.416 2.514zm-2-.006l-2.927-2.155-.491-.357-.588.808 3.014 2.193-.006 1.743.997.727.001-2.959zm2-3.8l.003 2.563.997-.735-.005-1.321 1.213-.859-.578-.816v.001l-1.63 1.167zm-2-.006l-1.628-1.162-.578.816 1.213.859-.005 1.316.998.735v-2.564z" /></svg>
              </div>
              <h6 className="text-xl mt-5 font-semibold text-black">
                Tree Structure
              </h6>
              <p className="mt-2 mb-4 text-gray-500">
                Tabs are displayed in a tree-like hierarchy. Each new tab is appended as a child automatically.
              </p>
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 card py-4">
              <div
                className="text-black"
              >
                <svg className={"w-6 h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.536 7.555c-1.188-.252-4.606-.904-5.536-1.088v-3.512c0-1.629-1.346-2.955-3-2.955s-3 1.326-3 2.955v7.457c-.554-.336-1.188-.621-1.838-.715-1.822-.262-3.162.94-3.162 2.498 0 .805.363 1.613 1.022 2.271 3.972 3.972 5.688 5.125 6.059 9.534h9.919v-1.748c0-5.154 3-6.031 3-10.029 0-2.448-1.061-4.157-3.464-4.668zm.357 8.022c-.821 1.483-1.838 3.319-1.891 6.423h-6.13c-.726-3.82-3.81-6.318-6.436-8.949-.688-.686-.393-1.37.442-1.373 1.263-.006 3.06 1.884 4.122 3.205v-11.928c0-.517.458-.955 1-.955s1 .438 1 .955v6.948c0 .315.256.571.572.571.314 0 .57-.256.57-.571v-.575c0-.534.49-.938 1.014-.833.398.079.686.428.686.833v1.273c0 .315.256.571.571.571s.571-.256.571-.571v-.83c0-.531.487-.932 1.008-.828.396.078.682.424.682.828v1.533c0 .315.256.571.571.571s.571-.256.571-.571v-.912c0-.523.545-.867 1.018-.646.645.305 1.166.932 1.166 2.477 0 1.355-.465 2.193-1.107 3.354z" /></svg>              </div>
              <h5 className="text-xl mt-5 font-semibold text-black">
                Drag & Drop
              </h5>
              <p className="mt-2 mb-4 text-gray-500">
                The most intuitive way for you to group the most important tabs together so you know exactly where they are.
              </p>
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 card py-4">
              <div
                className="text-black"
              >
                <svg className={"w-6 h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M1.004 6.251l10.996-5.998 10.99 6.06-10.985 5.861-11.001-5.923zm11.996 7.676v9.82l10-5.362v-9.82l-10 5.362zm-2 0l-10-5.411v9.869l10 5.362v-9.82z" /></svg>
              </div>
              <h5 className="text-xl mt-5 font-semibold text-black">
                Displays
              </h5>
              <p className="mt-2 mb-4 text-gray-500">
                Inline and external modes that come right out of the box to fit your every need.
              </p>
            </div>


            <div className="w-full lg:w-4/12 px-4 mb-10 card py-4">
              <div
                className="text-black"
              >
                <svg className={"w-6 h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 24l2.674-9h-9.674l16-15-2.674 9h8.674l-15 15zm-1.586-11h6.912l-1.326 4 5.739-6h-6.065l1.304-4-6.564 6z" /></svg>
              </div>
              <h6 className="text-xl mt-5 font-semibold text-black">
                Memory Saving
              </h6>
              <p className="mt-2 mb-4 text-gray-500">
                Automatically unloading tabs that are idle to provide a lag-free browsing experience.
              </p>
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 card py-4">
              <div
                className="text-black"
              >
                <svg className={"w-6 h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.538 7.674c-.292.129-.519.291-.714.477l-.646-.161c.259-.293.593-.552 1.015-.746.432-.194.886-.292 1.351-.332l-.098.51c-.313.041-.616.121-.908.252zm1.458.444c1.025 0 1.847.616 1.847 1.378 0 .77-.821 1.385-1.847 1.385-1.015 0-1.847-.616-1.847-1.385 0-.762.832-1.378 1.847-1.378zm-.826 1.379c0 .35.369.626.826.626.467 0 .837-.277.837-.626 0-.343-.37-.62-.837-.62-.457 0-.826.277-.826.62zm-1.313-2.674c.562-.251 1.178-.389 1.793-.421l.098-.495c-.767.017-1.534.179-2.236.495-.702.315-1.242.753-1.62 1.247l.638.169c.313-.396.766-.736 1.327-.995zm9.643 12.177c.276 0 .5-.224.5-.5s-.224-.5-.5-.5-.5.224-.5.5.224.5.5.5zm-.5-17h-14l-5 14v6h24v-6l-5-14zm-7.004 3c3.315 0 6.006 2.017 6.006 4.497 0 2.487-2.69 4.503-6.006 4.503s-5.994-2.017-5.994-4.503c0-2.48 2.679-4.497 5.994-4.497zm10.004 15h-13v-1.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v1.5h-1v-1.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v1.5h-1v-1.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v1.5h-2v-3h20v3z" /></svg>              </div>
              <h5 className="text-xl mt-5 font-semibold text-black">
                History
              </h5>
              <p className="mt-2 mb-4 text-gray-500">
                Always have a record of all your tree states. You can search and recover them whenever you want.
              </p>
            </div>
            <div className="w-full lg:w-4/12 px-4 mb-10 card py-4">
              <div
                className="text-black"
              >
                <svg className={"w-6 h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.313 5.998c.242.216.473.445.687.688l-1.165 1.166-.332-.356-.356-.331 1.166-1.167zm-2.653-1.56c.308.107.608.234.897.378l-.643 1.52c-.167-.088-.341-.168-.52-.239l-.373-.14.639-1.519zm-3.146-.438h.973v1.648l-.487-.018-.486.019v-1.649zm-2.125.419l.631 1.524-.416.153c-.165.067-.326.141-.483.22l-.63-1.524c.288-.142.59-.267.898-.373zm.716 6.829l1.045-1.045-1.463-1.466c.652-.464 1.451-.738 2.313-.738 2.21 0 4 1.791 4 4.001 0 2.209-1.79 3.999-4 3.999s-4-1.79-4-3.999c0-.813.242-1.567.658-2.199l1.447 1.447zm-3.418-5.25l1.167 1.166-.357.331-.332.357-1.165-1.166c.214-.243.445-.472.687-.688zm-1.871 2.443l1.52.641c-.087.168-.168.343-.238.52l-.14.376-1.52-.641c.109-.309.235-.608.378-.896zm-.816 3.07h1.649l-.019.485.019.486h-1.649v-.971zm.42 3.094l1.524-.63.153.417.219.48-1.524.632c-.141-.289-.266-.59-.372-.899zm2.13 3.527l-.688-.687 1.303-1.304.332.356.356.331-1.303 1.304zm2.79 1.43c-.308-.108-.608-.234-.897-.379l.643-1.52c.167.088.341.169.52.239l.375.14-.641 1.52zm3.146.438h-.973v-1.649l.486.019.486-.019v1.649zm2.124-.42l-.63-1.525.415-.152c.165-.066.326-.14.483-.22l.63 1.523c-.287.143-.589.268-.898.374zm2.703-1.586l-1.167-1.165.356-.331.332-.356 1.166 1.165c-.214.244-.445.473-.687.687zm1.871-2.441l-1.521-.643c.087-.168.169-.341.239-.518l.14-.378 1.52.642c-.109.307-.235.608-.378.897zm.816-3.071h-1.649l.019-.486-.019-.485h1.649v.971zm-1.944-2.464l-.153-.416-.219-.483 1.524-.629c.141.288.266.59.372.897l-1.524.631zm-6.056-8.018c5.514 0 10 4.486 10 10s-4.486 9.999-10 9.999-10-4.485-10-9.999 4.486-10 10-10zm0-2c-6.632 0-12 5.366-12 12 0 6.631 5.367 11.999 12 11.999 6.632 0 12-5.366 12-11.999 0-6.632-5.367-12-12-12z" /></svg>
              </div>
              <h5 className="text-xl mt-5 font-semibold text-black">Privacy</h5>
              <p className="mt-2 mb-4 text-gray-500">
                This is our priority and will remain that way. Your data is always stored locally, it&apos;s you to keep.
              </p>
            </div>




          </div>
        </div>
      </section>

      <section className="text-center container mx-auto xs:px-16 sm:px-8 md:px-8 lg:px-32">
        <div className="bg-black py-32 xs:py-8 px-8 xs:p-8 lg:p-16 text-white rounded-none sm:rounded-lg border-8 border-black">
          <h1 className={"font-bold text-4xl mb-5"}>Free to use. Forever.</h1>
          <p className={"text-lg text-gray-500"}>Get started for free and unlock great features when you upgrade. Check out our Pricing for more information.</p>
          <div className="">
            <a
                className="group bg-white shadow-md px-3 sm:px-6 py-2 rounded-lg inline-flex items-center space-x-4  hover:bg-gray-900 mt-10 mr-2 sm:mr-4"
                href="https://chrome.google.com/webstore/detail/treely-tree-style-tab-man/hbledhepdppepjnbnohiepcpcnphimdj"
            >
              <div className="logo">
                <img
                  className="w-6 h-6"
                  src={Chrome} />

              </div>
              <div className="text">
                <p className=" text-xs text-gray-600 group-hover:text-white" style={{ fontSize: "0.5rem" }}>Download on the</p>
                <p className=" text-xs font-semibold text-black group-hover:text-white">Chrome Store</p>
              </div>
            </a>

            <a
                className="group bg-white shadow-md px-3 sm:px-6  py-2 rounded-lg inline-flex items-center space-x-4 hover:bg-gray-900 mt-10 "
                href="https://microsoftedge.microsoft.com/addons/detail/treely-tree-style-tab-ma/lkohffhefinkknccfhhjpbnhgiibnkda?hl=en-GB"
            >
              <div className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-black w-6 h-6 group-hover:text-white" viewBox="0 0 24 24"><path d="M.93 10.653c.705-5.551 4.49-10.586 11.277-10.653 4.098.078 7.469 1.935 9.473 5.475 1.008 1.848 1.322 3.788 1.389 5.928v2.515h-15.061c.069 6.212 9.139 6 13.046 3.262v5.056c-1.522.915-4.331 1.764-7.225 1.764-7.198 0-10.13-5.411-10.112-9.05-.111-4.469 2.221-7.425 5.844-9.106-.768.954-1.352 2.004-1.658 3.818h8.505s.497-5.082-4.813-5.082c-5.01.177-8.622 3.09-10.665 6.073z" /></svg>
              </div>
              <div className="text">
                <p className=" text-xs text-gray-600 group-hover:text-white" style={{ fontSize: "0.5rem" }}>Download for</p>
                <p className=" text-xs font-semibold text-black group-hover:text-white">Microsoft Edge</p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;





